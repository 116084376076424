<template>
  <div class="auth-wrapper auth-v2" :style="backgroundStyle">
    <b-spinner v-if="isLoading" type="grow" class="mt-5 mx-auto d-block" />
    <b-row v-else class="auth-inner m-0" align-v="center">
      <b-col md="6" offset-md="3" class="my-md-2 my-0 d-flex align-items-center col p-0">
        <b-card v-if="community" class="px-3 px-md-2 mx-auto my-0 my-md-2 auth-bg w-100">
          <safe-img :src="communityLogo" class="signup-logo mb-2 mt-2 round" />
          <h1 class="font-weight-bold w-100 text-center mb-2" title-tag="h1">
            {{ translate(community.name) }}
          </h1>
          <b-card-text class="mb-2 text-center h4 text-ellipsis-lines-2">
            {{ $t("sign-up.subtitle") }}
          </b-card-text>

          <div v-if="community.accessibility === 2" class="d-flex flex-column align-items-center">
            <b-alert variant="danger" show class="text-center">
              <p class="mt-1 mb-2">
                {{ $t("sign-up.no-auth.message") }}
              </p>
            </b-alert>
            <b-link :href="loginURL">
              {{ $t("sign-up.no-auth.action") }}
            </b-link>
          </div>
          <div v-else-if="canRegister">
            <div v-if="success" class="d-flex flex-column align-items-center">
              <b-alert variant="success" show class="text-center">
                <h4>{{ $t("sign-up.success-message.title") }}</h4>
                <p class="text-dark">
                  {{ email }}
                </p>
                <p class="mt-1 mb-2 text-secondary">
                  {{ $t("sign-up.success-message.description") }}<b-link href="mailto:support@nectios.com"> {{ $t("sign-up.success-message.email") }}. </b-link>
                </p>
              </b-alert>
              <b-link :href="loginURL">
                {{ $t("sign-up.success-message.action") }}
              </b-link>
            </div>

            <b-form v-else class="auth-login-form mt-3" @submit.prevent="signup">
              <!-- email -->
              <label for="sign-up-email">{{ $t("sign-up.form.email.label") }}</label>
              <b-form-group>
                <b-form-input id="sign-up-email" v-model="userEmail" type="email" :placeholder="$t('sign-up.form.email.placeholder')" :label="$t('sign-up.form.email.label')" :state="isValid" @input="validator(userEmail)" />
              </b-form-group>

              <!-- Error Toast -->
              <div class="error-toast mb-1">
                <b-alert v-if="emailUsed" show variant="danger" class="m-0 error-notification">
                  {{ $t("sign-up.error-message.email-used") }}
                </b-alert>
                <b-alert v-if="generalError" show variant="danger" class="m-0 error-notification">
                  {{ $t("error-message.general-error") }}
                </b-alert>
              </div>

              <!-- Terms conditions & Privacy policy -->
              <b-card-text class="text-center mb-2">
                <span>{{ $t("sign-up.accept") }} </span>
                <b-link :href="termsConditionsURL">
                  <span>&nbsp;{{ $t("header.action.terms-conditions") }}</span>
                </b-link>
                <span> {{ $t("sign-up.and") }} </span>
                <b-link :href="privacyPolicyURL">
                  <span>&nbsp;{{ $t("header.action.privacy-policy") }}</span>
                </b-link>
              </b-card-text>

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" :disabled="!isValid" block>
                {{ $t("sign-up.form.action.sign-in") }}
              </b-button>
            </b-form>
            <b-card-text class="text-center small mt-5">
              <span>{{ $t("sign-up.sign-up.message") }}</span>
              <b-link :href="loginURL">
                {{ $t("sign-up.sign-up.action") }}
              </b-link>
            </b-card-text>
          </div>
          <div v-else>
            <p class="font-weight-bolder">El registro a la comunidad está cerrado.</p>
            <p v-if="!typeOfEvent" class="font-weight-bolder">
              {{ `La fecha de apertura es ${new Date(community.openingDate).toLocaleDateString()} ${community.closingDate != null ? " y la fecha de cierre es " + new Date(community.closingDate).toLocaleDateString() : null}` }}
            </p>
          </div>

          <b-card-text class="text-center login-community mt-3">
            <b-link :href="landingURL" target="_blank">
              <b-img :src="brand" alt="Power by Nectios" class="brand" />
            </b-link>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getImageResource } from "@/@core/utils/image-utils";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import BrandLogo from "@/assets/images/brand/powerby-nectios.svg";
import SafeImg from "@core/components/safe-img/SafeImg.vue";
import Service from "@/config/service-identifiers";
import { validatorEmailValidator } from "@/@core/utils/validations/validators";
import { getNewAuthRoute } from "@/auth/utils";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";

export default {
  name: "AuthSignup",
  components: {
    SafeImg,
  },
  mixins: [ToastNotificationsMixin],

  data() {
    return {
      userEmail: "",
      emailUsed: false,
      generalError: false,

      isLoading: true,
      community: null,
      email: null,

      from: null,
      path: null,
      success: false,
      cantRegister: false,
      isValid: null,
    };
  },
  computed: {
    communitySlug() {
      console.log(this.$route.params.communityId);
      return this.$route.params.communityId;
    },
    typeOfEvent() {
      if (this.community.isEvent || this.community.mainType?.toLowerCase() === "project") {
        if (this.community.occupation) {
          if (this.community.occupation.online != null && this.community.occupation["on-site"] != null) {
            return "hybrid";
          }
          if (this.community.occupation["on-site"]) {
            return "on-site";
          }
          ("online");
        } else {
          return false;
        }
      }
    },
    canOnline() {
      return this.community.onlineOccupation < this.community.occupation.online;
    },
    canOnsite() {
      if (this.community.onsiteOccupation == null) {
        return true;
      }
      return this.community.onsiteOccupation < this.community.occupation["on-site"];
    },
    termsConditionsURL() {
      return `${process.env.VUE_APP_LEGAL_CENTER_URL.replace("{subdomain}", this.community?.subdomain || "app").replace("{slug}", this.communitySlug)}/?legal=terms_conditions`;
    },
    privacyPolicyURL() {
      return `${process.env.VUE_APP_LEGAL_CENTER_URL.replace("{subdomain}", this.community?.subdomain || "app").replace("{slug}", this.communitySlug)}/?legal=privacy_policy`;
    },
    canRegister() {
      let canRegister = true;
      const now = new Date().getTime();
      if (this.community.openingDate != null && now < this.community.openingDate) {
        canRegister = false;
      } else if (this.community.closingDate != null && now > this.community.closingDate) {
        canRegister = false;
      } else if (this.community.accessibility > 2) {
        canRegister = false;
      }
      if (this.typeOfEvent) {
        if (!this.canOnline && !this.canOnsite) {
          canRegister = false;
        }
      }
      return canRegister;
    },
    backgroundStyle() {
      const backgrounds = this.community?.customization?.theme?.login?.backgrounds || [];
      if (backgrounds.length) {
        return {
          backgroundImage: `url(${getImageResource(backgrounds[0])}`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat !important",
        };
      }
      return null;
    },
    loginURL() {
      console.log(this.communitySlug, this.community.subdomain);
      return process.env.VUE_APP_LOGIN_TEMPLATE_URL_OLD.replace("{collectiveSubdomain}", this.community.subdomain || "app").replace("{collectiveSlug}", this.communitySlug);
    },
    communityLogo() {
      return getImageResource(this.community?.customization?.theme?.login?.logo || this.community?.logoURL || this.community?.header?.logo_url);
    },
    brand() {
      return BrandLogo;
    },
    landingURL() {
      return process.env.VUE_APP_LANDING_URL_COMMUNITY;
    },
  },
  watch: {
    communitySlug() {
      this.updateCommunity();
    },
    setLocale(newLocale) {
      this.$store.dispatch("setLocale", newLocale);
    },
  },
  async created() {
    this.isLoading = true;
    if (process.env.VUE_APP_ENV !== "development") {
      window.location.href = getNewAuthRoute({
        slug: this.$route.params.communityId || "",
        subdomain: window.location.host.split(".")[0] !== "app" ? `${window.location.host.split(".")[0]}.` : null,
      }, `/signup?referral_type=${this.$route?.query?.referral_type}&referral_id=${this.$route?.query?.referral_id}`);
      return;
    } else {
      await this.updateCommunity();
    }
    if (this.communitySlug === "barcelona-activa") {
      window.location.href = this.loginURL;
    }
    this.isLoading = false;
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    async updateCommunity() {
      const response = await this.$store.$service[Service.BackendClient].get("login", {
        params: { communitySlug: this.communitySlug },
      });
      this.community = response.data.data;
      /* Community colors */
      if (this.community.community_customizations) {
        const customization = this.community.community_customizations?.theme;
        if (customization?.primaryColor) {
          document.documentElement.style.setProperty("--primary-color", customization?.primaryColor);
          let hsl = customization?.primaryColor;
          hsl = hsl?.substring(4, hsl.length - 1);
          hsl = hsl?.split(",");
          document.documentElement.style.setProperty("--primary-color-h", hsl?.[0]);
          document.documentElement.style.setProperty("--primary-color-s", hsl?.[1]);
          document.documentElement.style.setProperty("--primary-color-l", hsl?.[2]);
        }
        if (customization?.secondaryColor) {
          document.documentElement.style.setProperty("--secondary-color", customization?.secondaryColor);
          let hslSecondary = customization?.secondaryColor;
          hslSecondary = hslSecondary?.substring(4, hslSecondary.length - 1);
          hslSecondary = hslSecondary?.split(",");
          document.documentElement.style.setProperty("--secondary-color-h", hslSecondary?.[0]);
          document.documentElement.style.setProperty("--secondary-color-s", hslSecondary?.[1]);
          document.documentElement.style.setProperty("--secondary-color-l", hslSecondary?.[2]);
        }
      }
      /* Finish colors */
      // if (this.community.showChatbot === 1) {
      //   const chatBot = document.createElement("script");
      //   chatBot.innerText = 'window.$crisp=[];window.CRISP_WEBSITE_ID="a1aa36a2-c0e5-44a4-bc75-c5f95f1af801";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
      //   document.body.appendChild(chatBot);
      // }
      if (this.community.showChatbot === 2) {
        const chatBot = document.createElement("div");
        chatBot.innerHTML = this.$store.getters.currentCollective?.customChatbot;
        document.body.appendChild(chatBot);
      }

      if (this.community.mainCommunitySlug && this.community.mainCommunitySlug !== this.communitySlug && !this.community.bypass) {
        this.from = this.communitySlug;
        this.path = this.$route.params.path;
        await this.$router.replace({ name: "auth-signup", params: { communityId: this.community.mainCommunitySlug } });
        return;
      }
      this.setLocale();
    },
    async signup() {
      this.emailUsed = false;
      this.generalError = false;
      try {
        const response = await this.$store.dispatch("signup", {
          email: this.userEmail,
          communitySlug: this.communitySlug,
          referralType: this.$route?.query?.referral_type,
          referralID: this.$route?.query?.referral_id,
        });
        if (response.status === 200 && response.data.nextStep) {
          this.$router.replace({
            name: "auth-register",
            params: { communityId: this.community.mainCommunitySlug },
            query: { token: response.data.token },
          });
        } else {
          this.email = this.userEmail;
          this.success = true;
        }
      } catch (error) {
        if (error.response.status === 400 && error.response.data.nextStep) {
          this.$router.replace({
            name: this.path,
            params: { communityId: this.$route.params.communityId },
          });
        } else if (error.response.status === 409 && error.response.data.EmailUsed) {
          this.emailUsed = true;
        } else if (error.response.status === 400 && error.response.data.CantRegister) {
          this.cantRegister = true;
        } else {
          this.generalError = true;
        }
      }
      return "";
    },

    setLocale() {
      this.$store.dispatch("setLocale", this.community.language);
    },
    validator(item) {
      this.isValid = validatorEmailValidator(item) && item !== "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";

.signup-logo {
  width: 120px;
  margin: auto;
  display: block;
}
.brand {
  height: 32px;
}

.error-notification {
  font-size: 12px;
}

.community-text {
  padding-left: 6px;
  margin-top: 4px;
  vertical-align: bottom;
  color: black;
}
@media (max-width: 767px) {
  .card {
    height: 100%;
    border-radius: 0;
  }
  .col {
    height: 100vh;
  }
}
</style>
